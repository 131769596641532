import UserLayout from '../Layout/UserLayout'
import Grid from '@mui/material/Grid'
import CardNavigationCenter from './components/CardNavigationCenter'
import axios from 'axios'
import Button from '@mui/material/Button'
import { useEffect, useRef, useState } from 'react'
import { MenuItem, TextField } from '@mui/material'
import jsPDF from 'jspdf';
import ReportDownloadView from "./ReportDownloadView";
import { pdf, PDFDownloadLink } from "@react-pdf/renderer";
import { saveAs } from 'file-saver';

export default function Report() {
    const authUserObj = localStorage.getItem('authUser');
    const authUser = JSON.parse(authUserObj);
    const [sessions, setSessions] = useState([]);
    const [currentSessionId, setCurrentSessionId] = useState('');
    const [selfEvaluations, setSelfEvaluations] = useState([]);
    const [currentUserId, setCurrentUserId] = useState();

    useEffect(() => {
        getSessions();
    }, [])

    const getSessions = async () => {
        await axios.get(`/api/sessions?status=0`).then(function (response) {
            let result = response.data;
            if (result.success) {                
                setSessions(result.data);
            } else {
                // setIsError(true);
            }
        });
    };
    const handleSessionChange = async (sessionId) => {
        setCurrentUserId('')
        setCurrentSessionId(sessionId)
        await getSessionsUsers(sessionId);
    }
    const getSessionsUsers = async (currentSessionId) => {
        await axios.get(`/api/self_evaluations?sessionId=${currentSessionId}`).then(function (response) {
            let result = response.data;
            if (result.success) {              
                setSelfEvaluations(result.data);
            } else {          
                // setIsError(true);
            }
        });
    };
    const handleUserChange = (userId) => {
        console.log("userId",userId)    
        setCurrentUserId(parseInt(userId))        
    }

    const handleDownload = async () => {
        // setIsReportLoading(true)
        try {
            const response = await axios.get(`/api/getReport?userId=${currentUserId}&sessionId=${currentSessionId}`);
            const result = response.data;
            if (result.success) {
                // console.log("====>truwwwwwwwww",result.data)
                // <ReportDownloadView report={result.data} />
                const blob = await pdf((
                    <ReportDownloadView report={result.data} />
                )).toBlob();
                saveAs(blob, "Deepika" + ".pdf");
            }
            // setIsReportLoading(false)
        }
        catch (error) {
            console.error('Error fetching executive summary:', error);
            // setIsReportLoading(false)
        }
    }

    const componentRef = useRef();

    // const handleGeneratePDF = async () => {
    //     const pdf = new jsPDF();
    //     const canvas = await html2canvas(componentRef.current);
    //     pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 15, 40, 180, 160);
    //     pdf.save('generated.pdf');
    // };

    // const exportPdf = () => {
    //     const input = document.getElementById("pdf-element");
    //     if (input) {
    //         const pdf = new jsPDF({
    //             orientation: 'l',
    //             unit: 'px',
    //             format: 'a4',
    //             compress:true,
    //             putOnlyUsedFonts:true
    //         });
    //         pdf.html(input, { html2canvas: { scale: 0.57 } }).then(() => {
    //             pdf.save("test.pdf");
    //         });
    //     }
    // };

    return (
        <>
        <UserLayout>        
            <Grid container spacing={6}>            
                <Grid item xs={12} md={2}>
                    <h1 className='mb-3'>Reports</h1>
                </Grid> 
                { sessions && sessions.length > 0 ?
                    <>
                        <Grid item xs={12} md={3}>
                            <TextField fullWidth
                            ListboxProps={{
                                sx: { fontSize: 13,
                                    fontFamily: 'Poppins' },
                              }}
                              sx={{
                                '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                    fontSize: 13,
                                    fontFamily: 'Poppins', // Add your desired font family
                                    fontWeight: '500', // Add your desired font weight
                                },
                              }}
                            select size='small' label='Session' id='sessionId' 
                            value={currentSessionId} 
                            onChange={(e) => handleSessionChange(Number(e.target.value))} >
                                { sessions.length > 0 ? 
                                    sessions.map(session => (
                                        <MenuItem key={session.id} value={session.id} >
                                                {session.name}
                                        </MenuItem>
                                    ))
                                : 
                                    <MenuItem value=''>No session available </MenuItem>
                                }                         
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            { authUser.role === 'Admin' ? 
                                <TextField fullWidth
                                ListboxProps={{
                                    sx: { fontSize: 13,
                                        fontFamily: 'Poppins' },
                                  }}
                                  sx={{
                                    '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                        fontSize: 13,
                                        fontFamily: 'Poppins', // Add your desired font family
                                        fontWeight: '500', // Add your desired font weight
                                    },
                                  }}
                                select size='small' label='User' id='userId' 
                                value={currentUserId} 
                                onChange={(e) => handleUserChange(Number(e.target.value))} >
                                    { selfEvaluations && selfEvaluations.length > 0 ? 
                                        selfEvaluations.map(selfEvaluation => (
                                            <MenuItem key={"user_"+selfEvaluation.id} value={selfEvaluation.user.id} >
                                                    {selfEvaluation.user.name}
                                            </MenuItem>
                                        ))
                                    : 
                                        <MenuItem value=''>No user available </MenuItem>
                                    }
                                </TextField>
                            : ''}
                        </Grid> 
                        {currentUserId > 0 ? 
                        <Grid item xs={12} md={3} className='text-end mt-1'>
                        {/* <PDFDownloadLink document={<ReportDownloadView />} fileName={"Rajiv.pdf"}>
                            {({ blob, url, loading, error }) =>
                                loading ? '' : 
                                 <Button size='small' variant='contained'>
                                    Download Report
                                </Button> 
                            }
                        </PDFDownloadLink>  */}
                        {/* <Button size='small' variant='contained' onClick={() => handleDownload()}>
                            Download Report 2
                        </Button>  */}
                           
                        </Grid> : ""}
                    </>
                :''}
                <Grid item xs={12} md={4}>
                </Grid>      
            </Grid>
              
            { sessions && sessions.length > 0 ?
                currentUserId > 0 ?  
                <Grid container spacing={6}>            
                    <Grid item xs={12} md={12}>
                        <CardNavigationCenter currentSessionId={currentSessionId} currentUserId={currentUserId}/>
                    </Grid>   
                </Grid>                 
                    
                : 
                    <Grid container spacing={6} justifyContent="center">            
                        <Grid item xs={12} md={6}>
                            <Grid sx={{ padding: 5}}><h6 className='mt-5 fw-normal'>Select session and its user to see the report.</h6></Grid>
                        </Grid>       
                    </Grid>
                :
                    <Grid container spacing={6} justifyContent="center">            
                        <Grid item xs={12} md={6}>
                            <Grid sx={{ padding: 5 }}><h6 className='mt-5 fw-normal'>No report available.</h6></Grid>
                        </Grid>       
                    </Grid>      
            }
            {/* <Pdf ref={componentRef}/> */}
        </UserLayout>
        </>
    )
}
import { fontWeight } from '@mui/system';
import React from 'react';
import Chart from 'react-apexcharts';

const PieComponent = ({chartData}) => {
  const series = [(chartData.self * 100)/7, (chartData.other * 100)/7, (chartData.average * 100)/7];
  const labels = ['Self', 'Your Score', 'Company'];

  const options = {
    chart: {
      height: 300,
      type: 'pie',
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      radialBar: {
        offsetY: 0,
        startAngle: 0,
        endAngle: 250,
        hollow: {
          margin: 5,
          size: '30%',
          background: 'transparent',
          image: undefined,
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            show: false,
          }
        }
      }
    },
    stroke: {
      lineCap: "round"
    },
    colors: ['#ff349b', '#2f669c', '#ffbf05'],
    labels: labels,
    title: {
      text: chartData.domain,
      align: 'center',
      margin: 0,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize:  '16px',
        fontWeight:  'bold',
        // fontFamily:  undefined,
        color:  '#263238'
      },
    },
    legend: {
      show: true,
      floating: true,
      fontSize: '12px',
      fontWeight: 600,
      position: 'left',
      offsetX: 0,
      offsetY: 0,
      labels: {
        useSeriesColors: true,
      },
      markers: {
        width: 4, // Set the width to 8 to reduce the size of the bullets (markers)
        height: 4, // Set the height to 8 to reduce the size of the bullets (markers)
        radius: 0, // You can adjust the radius if you want rounded markers
        strokeColor: '#000',
        fillColors: undefined,
        strokeWidth: 0,
        shape: "circle",
        offsetX: 0,
        offsetY: 0,
      },
      formatter: function (seriesName, opts) {
        return seriesName + ":  " + ((opts.w.globals.series[opts.seriesIndex]*7)/100).toFixed(1)
      },
      itemMargin: {
        vertical: 0,
        horizontal: 5
      }
    },
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
          show: false
        }
      }
    }]
  };

  return (
    <div className="custom-angle-circle-chart" >
      <Chart options={options} series={series} type="radialBar" height={220} />
    </div>
  );
};

export default PieComponent;
